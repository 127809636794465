"use client";
import {
	Box,
	Grid,
} from "@mui/material";
import BazaarCTA from "components/BazaarCta";
import CustomTooltip from "components/CustomToolTip";
import LazyImage from "components/LazyImage";
import {
	H2,
	H5,
} from "components/Typography";
import Carousel from "components/carousel/Carousel";
import { useBackendConstants } from "contexts/BackendConstantsContext";
import { useNavigation } from "contexts/NavigationContext";
import useDeviceSize from "hooks/useDeviceSize";
import Link from "next/link";
import {
	FC,
	useEffect,
	useRef,
	useState,
} from "react";
import { SECTION_ROOT } from "utils/config";

export interface IndexHeroProps {}

const IndexHero: FC<
	IndexHeroProps
> = ({}) => {
	const [
		currentSlide,
		setCurrentSlide,
	] = useState(0);
	const sliderRef = useRef(null);
	const [autoPlay, setAutoPlay] =
		useState(false);

	//  use effect to determine if the slider is on view and set autoPlay to true otherwise set it to false

	useEffect(() => {
		// Intersection Observer API
		const observer =
			new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							setAutoPlay(true);
						} else {
							setAutoPlay(false);
						}
					});
				},
				{
					threshold: 0.5,
				}
			);

		if (sliderRef.current) {
			observer.observe(
				sliderRef.current
			);
		}

		return () => {
			//  disconnect the observer when the component is unmounted
			observer.disconnect();
		};
	}, [autoPlay, sliderRef]);

	const { mainSections } =
		useNavigation();

	const { upMd } = useDeviceSize();

	const {
		return: {
			delay_in_days: returnDelayInDays,
		},
	} = useBackendConstants();

	const heroSlides = getHeroSlides({
		returnDelayInDays,
	});

	const CtaGrid = () => {
		return (
			<Grid
				item
				xs={12}>
				{/* CTA Text */}
				<H5
					component='h3'
					sx={{ mb: 1 }}>
					{/* What do you want to start with */}
					Par quoi voulez-vous commencer
					?
				</H5>
				{/* CTA Buttons */}
				<Grid
					item
					xs={12}
					container
					spacing={1}
					flexDirection='row'>
					{mainSections?.map(
						(section) => {
							return (
								<Grid
									key={section.id}
									item
									sm={
										12 /
										mainSections.length
									}
									xs={12}>
									<BazaarCTA
										fullWidth
										LinkComponent={Link}
										href={`${SECTION_ROOT}/${section.handle}`}
										color={
											heroSlides[
												currentSlide
											].color ===
											"#ffffff"
												? "white"
												: "black"
										}>
										{section.name}
									</BazaarCTA>
								</Grid>
							);
						}
					)}
				</Grid>
			</Grid>
		);
	};

	return (
		<Grid
			container
			flexDirection='row'
			spacing={3}
			p={3}
			maxWidth='100%'
			m='0px !important'
			bgcolor={
				heroSlides[currentSlide]
					.bgColor ?? undefined
			}
			color={
				heroSlides[currentSlide]
					.color ?? undefined
			}>
			{!upMd && CtaGrid()}
			{/* Images carroussel */}
			<Grid
				item
				xs={12}
				md={6}>
				<div ref={sliderRef}>
					<Carousel
						autoPlay={autoPlay}
						totalSlides={
							heroSlides.length
						}
						visibleSlides={1}
						currentSlide={currentSlide}
						interval={5000}
						onCurrentSliderChange={(
							newSlide
						) =>
							setCurrentSlide(newSlide)
						}
						showDots
						dotColor={
							heroSlides[currentSlide]
								.color
						}
						leftButtonStyle={{
							backgroundColor:
								currentSlide === 0
									? "transparent"
									: heroSlides[
											currentSlide
									  ].color,
							color:
								currentSlide === 0
									? "transparent"
									: heroSlides[
											currentSlide
									  ].bgColor.length > 7
									? heroSlides[
											currentSlide
									  ].bgColor.slice(
											0,
											-2
									  )
									: heroSlides[
											currentSlide
									  ].bgColor,
						}}
						rightButtonStyle={{
							backgroundColor:
								currentSlide ===
								heroSlides.length - 1
									? "transparent"
									: heroSlides[
											currentSlide
									  ].color,
							color:
								currentSlide ===
								heroSlides.length - 1
									? "transparent"
									: heroSlides[
											currentSlide
									  ].bgColor.length > 7
									? heroSlides[
											currentSlide
									  ].bgColor.slice(
											0,
											-2
									  )
									: heroSlides[
											currentSlide
									  ].bgColor,
						}}>
						{heroSlides.map(
							(slide, ind) => (
								<Box
									key={ind}
									width='100%'
									maxHeight={500}
									sx={{
										position:
											"relative",
										"&::before": {
											content: '""',
											display: "block",
											paddingTop: "73%", // aspect ratio
										},
									}}>
									<LazyImage
										key={ind}
										src={slide.image}
										alt={slide.title}
										fill
										priority
										sx={{
											position:
												"absolute",
											top: 0,
											left: 0,
											width: "100%",
											height: "100%",
											objectFit:
												"contain",
										}}
										sizes='(max-width: 600px) 100vw, 400px'
									/>
								</Box>
							)
						)}
					</Carousel>
				</div>
			</Grid>
			{/* text and CTA */}
			<Grid
				item
				xs={12}
				md={6}
				container
				flexDirection='row'
				flexWrap='wrap'
				alignContent='space-between'>
				{/* Text */}
				<Grid
					item
					xs={12}>
					{/* {heroSlides[currentSlide]
						.icon && (
						<LazyImage
							src={
								heroSlides[currentSlide]
									.icon ?? ""
							}
							alt='icon'
							width={150}
							height={40}
						/>
					)} */}
					<H2
						display='flex'
						my={2}
						sx={{
							fontFamily:
								"'Oswald', sans-serif",
							textTransform:
								"uppercase",
							fontSize: "2.0rem",
						}}>
						{
							heroSlides[currentSlide]
								.title
						}
						{heroSlides[currentSlide]
							.tip && (
							<CustomTooltip
								description={
									heroSlides[
										currentSlide
									].tip ?? ""
								}
								variant='info'
							/>
						)}
					</H2>

					<H2
						component='h2'
						sx={{
							fontWeight: "normal",
							fontFamily:
								"'Roboto', sans-serif",
							fontSize: "2.0rem",
						}}>
						{
							heroSlides[currentSlide]
								.subtitle
						}
					</H2>
				</Grid>
				{/* CTA */}
				{upMd && CtaGrid()}
			</Grid>
		</Grid>
	);
};

const getHeroSlides = ({
	returnDelayInDays,
}: {
	returnDelayInDays: number;
}) => {
	return [
		{
			title: "Expérience Inégalée",
			subtitle:
				"Seconde main, premier choix. Découvrez des milliers de pièces uniques.",
			tip: null,
			image:
				"/assets/images/banners/noom-1.jpg",
			icon: null,
			// "assets/images/icons/hero-experience.svg",
			color: "#000000",
			bgColor: "#4CFFBA",
		},
		{
			title: "Vos Marques préférées",
			subtitle:
				"Les créateurs que vous adorez en un seul endroit avec jusqu'à 90 % de réduction.",
			tip: null,
			image:
				"/assets/images/banners/noom-3.png",
			icon: null,
			// "assets/images/icons/hero-brands.svg",
			color: "#ffffff",
			bgColor: "#9f0126",
		},
		{
			title:
				"Livraison et Retours Faciles",
			subtitle:
				"Essayez, aimez, ou retournez gratuitement",
			tip: `Délai de retour : ${returnDelayInDays} jours`,
			image:
				"/assets/images/banners/noom-2.png",
			icon: null,
			// "assets/images/icons/hero-delivery.svg",
			color: "#000000",
			bgColor: "#FFCD3E",
		},
		{
			title: "Personnalisé Pour Vous",
			subtitle:
				"Des suggestions basées sur votre style",
			tip: null,
			image:
				"/assets/images/banners/noom-4.png",
			icon: null,
			// "assets/images/icons/hero-style-profile.svg",
			bgColor: "#0565b9",
			color: "#ffffff",
		},
		{
			title: "Trouvez Votre Taille",
			subtitle:
				"Des filtres intelligents pour un shopping efficace",
			tip: null,
			image:
				"/assets/images/banners/noom-5.png",
			icon: null,
			// "assets/images/icons/hero-style-profile.svg",
			bgColor: "#d86a03",
			color: "#ffffff",
		},
		{
			title: "Transparence Totale",
			subtitle:
				"Tout savoir sur la provenance et la qualité",
			tip: null,
			image:
				"/assets/images/banners/noom-6.png",
			icon: null,
			// "assets/images/icons/hero-sustainability.svg",
			bgColor: "#BCC92D",
			color: "#000000",
		},
		{
			title: "Paiement Sécurisé",
			subtitle:
				"Des options de paiement flexibles et sécurisées",
			tip: null,
			image:
				"/assets/images/banners/noom-7.png",
			// icon: null,
			// icon : "assets/images/icons/hero-payment.svg",
			bgColor: "#332b2ced",
			color: "#ffffff",
		},
	];
};

export default IndexHero;
